import React from "react"
import Auth from "@helpers/auth"
import Layout from "@components/layout"
import useComponentDidMount from "@helpers/useComponentDidMount"
import Container from "@components/styled/Container"
import Box from "@components/styled/Box"
import Loading from "@components/Loading"
const Auth0CallbackPage = () => {
  useComponentDidMount(() => {
    const auth = new Auth()
    auth.handleAuthentication()
  })

  return (
    <Layout>
      <Container>
        <Box textAlign="center" p={7}>
          <h1>
            This is the auth callback page, you should be redirected
            immediately.
          </h1>

          <Loading />
        </Box>
      </Container>
    </Layout>
  )
}

export default Auth0CallbackPage
